<template>
  <div class="content">
    <div class="title-actions">
      <h5 class="title">{{ division.name }} - {{$t('teamWeek')}}</h5>
      <div class="btns">
        <b-button variant="info" @click="create">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered :items="list" :fields="columns">
      <div class="text-right" slot="order" slot-scope="row">{{ row.index + 1 }}</div>
      <template slot="name" slot-scope="row">{{$t('teamWeek')}} {{ row.item.name }}</template>
    </b-table>
    <b-form @submit.prevent="save" ref="formTeamWeek">
      <b-modal id="modalTeamWeek" :title="$t('create')" @show="init" no-close-on-backdrop no-close-on-esc>
        <div class="form-group">
          <label for="name">{{ $t('name') }}</label>
          <b-form-input v-model="current.name" name="name" id="name" v-validate="'required|max:50'" :state="validateState('name')" />
          <span class="error-inputs">{{ errors.first('name') }}</span>
        </div>
        <div class="form-group">
          <label for="formation">{{ $t('training') }}</label>
          <select name="formation" id="formation" class="form-control" v-validate="'required|max:50'" :state="validateState('formation')" v-model="current.formation" @change="findPlayers">
            <option value="">{{ $t('selectOption') }}</option>
            <option value="1">4-1-2-1-2</option>
            <option value="2">4-2-2-2</option>
          </select>
          <span class="error-inputs">{{ errors.first('formation') }}</span>
        </div>
        <!-- Seleccionar -->
        <item-team-week :players="gks" label="1 - GK" name="gk" type="single" />
        <item-team-week :players="dfcs" label="2 - DFC" name="dfc" type="multiple" />
        <item-team-week :players="dfis" label="1 - DFI" name="dfi" type="single" />
        <item-team-week :players="dfds" label="1 - DFD" name="dfd" type="single" />
        <item-team-week :players="mcos" label="1 - MCO" name="mco" type="single" />
        <item-team-week v-if="current.formation === '1'" :players="mcds" label="1 - MCD" name="mcd" type="single" />
        <item-team-week v-else :players="mcds" label="2 - MCD" name="mcd" type="multiple" />
        <item-team-week :players="mis" label="1 - MI" name="mi" type="single" />
        <item-team-week :players="mds" label="1 - MD" name="md" type="single" />
        <item-team-week :players="sts" label="2 - ST" name="st" type="multiple" />
        <div v-if="dts.length > 0" class="form-group">
          <label for="dt">DT</label>
          <select class="form-control" name="dt" id="dt" required>
            <option v-for="(dt, i) in dts" :key="`dt-${i}`" :value="`${dt.id}-${dt.team_id}`">{{ dt.nick }}</option>
          </select>
        </div>
        <template slot="modal-footer">
          <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalTeamWeek')">{{ $t('cancel') }}</b-button>
          <b-button type="submit" variant="primary">{{ $t('create') }}</b-button>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>
<script>
import ItemTeamWeek from './partials/ItemTeamWeek'
import { mapGetters } from 'vuex'

export default {
  components: {
    ItemTeamWeek
  },
  data () {
    return {
      list: [],
      current: {
        formation: '',
        name: ''
      },
      columns: [
        { key: 'order', label: '#', sortable: false },
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'training', label: this.$t('training'), sortable: true },
        { key: 'created_at', label: this.$t('createdAgo'), sortable: true }
      ],
      gks: [],
      gksSel: [],
      dfcs: [],
      dfis: [],
      dfds: [],
      sts: [],
      mis: [],
      mds: [],
      mcds: [],
      mcos: [],
      dts: [],
      tournament: {},
      division: {}
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    findPlayers () {
      if (this.current.formation) {
        const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/division/${this.division.id}/team-week/formation/${this.current.formation}`
        this.$axios.get(path).then((response) => {
          const data = response.data
          this.gks = data.gks
          this.dfcs = data.dfcs
          this.dfis = data.dfis
          this.dfds = data.dfds
          this.sts = data.sts
          this.mis = data.mis
          this.mds = data.mds
          this.mcds = data.mcds
          this.mcos = data.mcos ? data.mcos : []
          this.dts = data.dt
        })
      }
    },
    init () {
      this.current = {
        formation: '',
        name: ''
      }
      this.gks = []
      this.dfcs = []
      this.dfis = []
      this.dfds = []
      this.sts = []
      this.mis = []
      this.mds = []
      this.mcds = []
      this.mcos = []
      this.dts = []
    },
    create () {
      this.$root.$emit('bv::show::modal', 'modalTeamWeek')
    },
    save () {
      const payload = this.serializeForm(this.$refs.formTeamWeek)    
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/division/${this.division.id}/team-week/store`
      this.$axios.post(path, payload).then(() => {
        this.$root.$emit('bv::hide::modal', 'modalTeamWeek')
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    fetchData () {
      const tournamentId = this.$route.params.tournamentId
      const divisionId = this.$route.params.divisionId
      const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/division/${divisionId}/team-week`
      this.$axios.get(path).then((response) => {
        const data = response.data
        this.list = data.list
        this.tournament = data.tournament
        this.division = data.division
        document.title = `${this.division.name} - ${this.$t('teamWeek')}`
      })
    },
    serializeForm (form) {
      const isValidElement = element => {
        return element.name && element.value
      }
      const isValidValue = element => {
        return (!['checkbox', 'radio'].includes(element.type) || element.checked)
      }
      const isCheckbox = element => element.type === 'checkbox'
      const isMultiSelect = element => element.options && element.multiple
      const getSelectValues = options => [].reduce.call(options, (values, option) => {
        return option.selected ? values.concat(option.value) : values
      }, [])
      const formToJSON_deconstructed = elements => {
        const reducerFunction = (data, element) => {
          data[element.name] = element.value
          return data
        }
        const reducerInitialValue = {}
        const formData = [].reduce.call(elements, reducerFunction, reducerInitialValue)
        return formData
      }
      const formToJSON = elements => [].reduce.call(elements, (data, element) => {
        if (isValidElement(element) && isValidValue(element)) {
          if (isCheckbox(element)) {
            data[element.name] = (data[element.name] || []).concat(element.value)
          } else if (isMultiSelect(element)) {
            data[element.name] = getSelectValues(element)
          } else {
            data[element.name] = element.value
          }
        }
        return data
      }, {})
      return formToJSON(form.elements)
    }
  }
}
</script>
